import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

// Create Document Component
export const PdfBalanceSheet = (props) => {
  const tableRowsCredit = [...props.creditors, ...props.liabilites_ledgers].map(
    (arrVoucher, index) => (
      <TableRow
        col1={arrVoucher.account_head}
        col2={Math.round(arrVoucher.closing_balance * 1)}
      />
    )
  );

  const tableRowsDebt = [...props.debtors, ...props.asset_ledgers].map(
    (arrVoucher, index) => (
      <TableRow
        col1={arrVoucher.account_head}
        col2={Math.round(-1 * arrVoucher.closing_balance)}
      />
    )
  );
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.top}></View>
        <View style={styles.container1}>
          <View style={styles.section}>
            <Text>AHS Marine Sea Food</Text>
            <Text style={styles.address}>
              5-27, M-1. Maria Street, Kanyakumari Dist{props.invoice_no}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            alignItems: "center",
            padding: 5,
            fontWeight: "bold",
          }}
        >
          <Text>BALANCE SHEET</Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={styles.col}>
            <TableRow col1="ASSETS" col2={"AMOUNT"} bold head />
            {tableRowsCredit}
            {props.loss > 0 && (
              <TableRow col1="LOSS" col2={Math.round(props.loss)} />
            )}
            <TableRow col1="STOCK" col2={props.closing_stock} />
            <TableRow col1="TOTAL" col2={Math.round(props.totalDebt)} bold />
          </View>
          <View style={styles.col}>
            <TableRow col1="LIABILITIES" col2={"AMOUNT"} bold head />
            {tableRowsDebt}
            <TableRow
              col1="CASH ACCOUNT"
              col2={Math.round(Math.round(props.cash_acc))}
            />
            <TableRow col1="ICICI BANK" col2={Math.round(props.icici_acc)} />
            {/* <TableRow col1="OPENING STOCK" col2={Math.round(props.opening_stock)} /> */}
            {/* <TableRow
              col1="STOCK (PACKING MATERIAL)"
              col2={Math.round(props.pack_stock)}
            /> */}
            {props.loss < 0 && (
              <TableRow col1="PROFIT" col2={Math.round(-1 * props.loss)} />
            )}
            <TableRow
              col1="TOTAL"
              col2={Math.round(-1 * props.totalCredit)}
              bold
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

const TableRow = ({ col1, col2, bold, head }) => {
  return (
    <View style={styles.tableData}>
      <Text
        style={[styles.col1, bold && styles.bold, head && styles.tableHeader]}
      >
        {col1}
      </Text>
      <Text
        style={[styles.col2, bold && styles.bold, head && styles.tableHeader]}
      >
        {col2}
      </Text>
    </View>
  );
};

Font.register({
  family: "Roboto",
  src: require("../../fonts/Roboto-Bold.ttf"),
});
Font.register({
  family: "Roboto-Italic",
  src: require("../../fonts/Roboto-BoldItalic.ttf"),
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fbfbfc",
    padding: 20,
    flexDirection: "column",
  },
  col: {
    width: "49%",
  },
  heading: {
    fontSize: 12,
    fontFamily: "Roboto",
    marginVertical: 10,
    paddingHorizontal: 5,
  },
  tableData: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#ccc",
  },
  col1: {
    width: "50%",
    paddingHorizontal: 5,
    paddingVertical: 5,
    fontSize: 10,
  },
  col2: {
    width: "50%",
    paddingHorizontal: 5,
    paddingVertical: 5,
    fontSize: 10,
    textAlign: "right",
  },
  col3: {
    width: "20%",
    textAlign: "right",
    paddingHorizontal: 5,
    paddingVertical: 5,
    fontSize: 10,
  },
  bold: {
    fontFamily: "Roboto",
  },
  tableHeader: {
    backgroundColor: "#fa6a44",
  },
  //////////////////
  top: {
    backgroundColor: "#fa6a44",
    padding: 10,
  },
  container1: {
    backgroundColor: "#eaeaea",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container2: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container3: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  section: {
    margin: 10,
    padding: 10,
    paddingHorizontal: 10,
    width: "50%",
  },
  section3rd: {
    margin: 10,
    padding: 10,
    paddingHorizontal: 10,
    width: "30%",
  },
  sectionRow: {
    flexDirection: "column",
    margin: 10,
    padding: 10,
  },
  addressTitle: {
    color: "#034a7e",
    borderBottomWidth: 1,
    borderColor: "#ccc",
    fontSize: 10,
    fontFamily: "Roboto",
  },
  dataTitle: {
    fontSize: 10,
    fontFamily: "Roboto",
  },
  address: {
    paddingVertical: 4,
    fontSize: 10,
    lineHeight: 2,
  },
  colFull: {
    paddingHorizontal: 5,
    paddingVertical: 5,
    fontSize: 10,
    fontFamily: "Roboto-Italic",
  },

  sectionBottomRight: {
    marginTop: 15,
    flex: 1,
    justifyContent: "center",
  },
  sectionTopRight: {
    paddingVertical: 5,
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  box: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 2,
  },
  left: {
    flex: 0.6,
    fontSize: 10,
    textAlign: "right",
    fontFamily: "Roboto",
  },
  leftBalance: {
    flex: 0.6,
    fontSize: 13,
    textAlign: "right",
    fontFamily: "Roboto",
  },
  right: {
    flex: 0.4,
    fontSize: 10,
    textAlign: "left",
    paddingHorizontal: 10,
  },
  rightBottom: {
    flex: 0.4,
    fontSize: 10,
    textAlign: "right",
    paddingHorizontal: 10,
    fontFamily: "Roboto",
  },

  rightBalance: {
    flex: 0.4,
    paddingHorizontal: 10,
    fontSize: 13,
    textAlign: "right",
    fontFamily: "Roboto",
  },
});
