import React, { Component, useEffect } from "react";
import {
  URL_PL_PURCHASE,
  URL_PL_SALES,
  URL_PL_EXPENSES,
  URL_PL_INCOME,
  URL_PL_PAYROLL,
  URL_PL_INV_PACK_EXP,
  URL_PL_INV_FREIGHT_EXP,
  URL_PL_INV_OTHER_EXP,
} from "./constants";
import {
  URL_PL_PURCHASE_DT,
  URL_PL_SALES_DT,
  URL_PL_EXPENSES_DT,
  URL_PL_INCOME_DT,
  URL_PL_PAYROLL_DT,
  URL_PL_INV_PACK_EXP_DT,
  URL_PL_INV_FREIGHT_EXP_DT,
  URL_PL_INV_OTHER_EXP_DT,
} from "./constants";
import {
  URL_BS_CREDITORS,
  URL_BS_DEBTORS,
  URL_BS_CASH_BAL,
  URL_BS_STOCK,
} from "./constants";
import DatePicker from "react-date-picker";
import Nav from "../NavBar";
import { JsonToCsv, useJsonToCsv } from "react-json-csv";
import { PDFViewer } from "@react-pdf/renderer";
import { PdfBalanceSheet } from "./pdf/balanceSheet";

class BalanceSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: "2020-01-01",
      toDate: new Date(),
      purchase_total: "",
      sales_total: "",
      income: [],
      expense: [],
      payroll: "",
      inv_packing_exp: "",
      inv_freight_exp: 0,
      inv_other_exp: "",
      dateFrom: new Date(),
      dateTo: new Date(),

      debtors: [],
      rates: [],
      creditors: [],
      asset_ledgers: [],
      liabilites_ledgers: [],

      cash_acc: 0,
      icici_acc: 0,
      fish_stock: 0,
      pack_stock: 0,
      closing_stock: "",
      opening_stock: "",
      showPdf: false,
      loading: false,
    };
    this.onDateFromChange = this.onDateFromChange.bind(this);
    this.onDateToChange = this.onDateToChange.bind(this);
    this.changeRate = this.changeRate.bind(this);
  }

  componentDidMount() {
    this.loadReport();
  }

  handleChangeClosingStock(e) {
    this.setState({ closing_stock: e.target.value });
  }

  handleChangeOpeningStock(e) {
    this.setState({ opening_stock: e.target.value });
  }

  async loadReport() {
    this.setState({ loading: true });
    await this.loadSalesTotal();
    await this.loadIncome();

    await this.loadPurchaseTotal();
    await this.loadExp();
    await this.loadPayroll();
    await this.loadInvPackingExp();
    //  this.loadInvFreightExp();
    await this.loadInvOtherExp();

    await this.loadSundryDebtors();
    await this.loadSundryCreditors();
    await this.loadCashAccBal();
    await this.loadICICIAccBal();
    await this.loadFishStock();
    // await this.loadPackStock();
    this.setState({ loading: false });
  }

  handleChangePurchase(e) {
    this.setState({ purchase: e.target.value });
  }

  loadPurchaseTotal() {
    return new Promise((success, reject) => {
      fetch(
        URL_PL_PURCHASE +
          `/'${this.state.fromDate}'/'${this.formatDate(this.state.toDate)}'`
      )
        .then((response) => response.json())
        .then((data) =>
          this.setState({ purchase_total: data[0].totalPurchase })
        )
        .then(() => success())
        .catch(() => reject());
    });
  }

  loadSalesTotal() {
    return new Promise((success, reject) => {
      fetch(
        URL_PL_SALES +
          `/'${this.state.fromDate}'/'${this.formatDate(this.state.toDate)}'`
      )
        .then((response) => response.json())
        .then((data) => this.setState({ sales_total: data[0].totalSales }))
        .then(() => success())
        .catch(() => reject());
    });
  }

  loadExp() {
    return new Promise((success, reject) => {
      fetch(
        URL_PL_EXPENSES +
          `/'${this.state.fromDate}'/'${this.formatDate(this.state.toDate)}'`
      )
        .then((response) => response.json())
        .then((data) => this.setState({ expense: data }))
        .then(() => success())
        .catch(() => reject());
    });
  }

  loadIncome() {
    return new Promise((success, reject) => {
      fetch(
        URL_PL_INCOME +
          `/'${this.state.fromDate}'/'${this.formatDate(this.state.toDate)}'`
      )
        .then((response) => response.json())
        .then((data) => this.setState({ income: data }))
        .then(() => success())
        .catch(() => reject());
    });
  }

  loadPayroll() {
    return new Promise((success, reject) => {
      fetch(
        URL_PL_PAYROLL +
          `/'${this.state.fromDate}'/'${this.formatDate(this.state.toDate)}'`
      )
        .then((response) => response.json())
        .then((data) => this.setState({ payroll: data[0].amount }))
        .then(() => success())
        .catch(() => reject());
    });
  }

  loadInvPackingExp() {
    return new Promise((success, reject) => {
      fetch(
        URL_PL_INV_PACK_EXP +
          `/'${this.state.fromDate}'/'${this.formatDate(this.state.toDate)}'`
      )
        .then((response) => response.json())
        .then((data) => this.setState({ inv_packing_exp: data[0].amount }))
        .then(() => success())
        .catch(() => reject());
    });
  }

  loadInvFreightExp() {
    return new Promise((success, reject) => {
      fetch(
        URL_PL_INV_FREIGHT_EXP +
          `/'${this.state.fromDate}'/'${this.formatDate(this.state.toDate)}'`
      )
        .then((response) => response.json())
        .then((data) => this.setState({ inv_freight_exp: data[0].amount }))
        .then(() => success())
        .catch(() => reject());
    });
  }

  loadInvOtherExp() {
    return new Promise((success, reject) => {
      fetch(
        URL_PL_INV_OTHER_EXP +
          `/'${this.state.fromDate}'/'${this.formatDate(this.state.toDate)}'`
      )
        .then((response) => response.json())
        .then((data) => this.setState({ inv_other_exp: data[0].amount }))
        .then(() => success())
        .catch(() => reject());
    });
  }

  onDateFromChange = (dateFrom) => {
    this.setState({ toDate: dateFrom }, () => {
      this.loadReport();
    });
  };

  onDateToChange = (dateTo) => {
    this.setState({ toDate: this.formatDate(dateTo), dateTo });
  };

  //Balance Sheet

  changeRate(account_head, rate) {
    var _rates = this.state.rates;
    var res = this.state.debtors.filter(function (item) {
      return item.account_head == account_head;
    });
    const index = this.state.debtors.indexOf(res[0]);
    _rates[index] = rate;
    this.setState({ rates: _rates });
  }

  loadSundryDebtors = () => {
    return new Promise((success, reject) => {
      const date = this.formatDate(this.state.toDate);
      fetch(URL_BS_DEBTORS + `/${0}/'${date}'`)
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            debtors: data,
          });
        })
        .then(() => success())
        .catch(() => reject());
    });
  };

  loadSundryCreditors = () => {
    return new Promise((success, reject) => {
      const date = this.formatDate(this.state.toDate);
      fetch(URL_BS_CREDITORS + `/${0}/'${date}'`)
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            creditors: data,
          });
        })
        .then(() => success())
        .catch(() => reject());
    });
  };

  loadAssetLedgers = () => {
    return new Promise((success, reject) => {
      const date = this.formatDate(this.state.toDate);
      fetch(URL_BS_DEBTORS + `/${12}/'${date}'`)
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            asset_ledgers: data,
          });
        })
        .then(() => success())
        .catch(() => reject());
    });
  };

  loadLiabilityLedgers = () => {
    return new Promise((success, reject) => {
      const date = this.formatDate(this.state.toDate);
      fetch(URL_BS_CREDITORS + `/${13}/'${date}'`)
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            liabilites_ledgers: data,
          });
        })
        .then(() => success())
        .catch(() => reject());
    });
  };

  loadCashAccBal() {
    return new Promise((success, reject) => {
      fetch(
        URL_BS_CASH_BAL + `/'${this.formatDate(this.state.toDate)}'/'${10004}'`
      )
        .then((response) => response.json())
        .then((data) => this.setState({ cash_acc: data[0].balance }))
        .then(() => success())
        .catch(() => reject());
    });
  }

  loadICICIAccBal() {
    return new Promise((success, reject) => {
      fetch(
        URL_BS_CASH_BAL + `/'${this.formatDate(this.state.toDate)}'/'${10003}'`
      )
        .then((response) => response.json())
        .then((data) => this.setState({ icici_acc: data[0].balance }))
        .then(() => success())
        .catch(() => reject());
    });
  }

  loadFishStock() {
    return new Promise((success, reject) => {
      fetch(URL_BS_STOCK + `/'FISH'`)
        .then((response) => response.json())
        .then((data) => this.setState({ fish_stock: data[0].stock }))
        .then(() => success())
        .catch(() => reject());
    });
  }

  loadPackStock() {
    return new Promise((success, reject) => {
      fetch(URL_BS_STOCK + `/'PACKING MATERIAL'`)
        .then((response) => response.json())
        .then((data) => this.setState({ pack_stock: data[0].stock }))
        .then(() => success())
        .catch(() => reject());
    });
  }

  formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  exportCSV({ filename, fields, url }) {
    const { saveAsCsv } = useJsonToCsv();
    const style = {
      padding: "5px",
    };

    fetch(
      url + `/'${this.state.fromDate}'/'${this.formatDate(this.state.toDate)}'`
    )
      .then((response) => response.json())
      .then((data) => data && saveAsCsv({ data, fields, filename }));
  }

  render() {
    const totalInc =
      this.state.sales_total +
      this.state.income.reduce((a, b) => +a + +b.amount, 0);

    const totalExp =
      this.state.purchase_total +
      this.state.expense.reduce((a, b) => +a + +b.amount, 0) +
      this.state.payroll +
      this.state.inv_packing_exp +
      this.state.inv_freight_exp +
      this.state.inv_other_exp;

    const loss = totalExp - totalInc;

    const tableRowsDebt = [
      ...this.state.debtors,
      ...this.state.asset_ledgers,
    ].map((arrVoucher, index) => (
      <TableRowDebt
        arrVoucher={arrVoucher}
        rate={this.state.rates[index]}
        changeRate={this.changeRate}
      />
    ));
    const rates = this.state.rates;
    const totalDebt =
      [...this.state.creditors, ...this.state.liabilites_ledgers].reduce(
        (a, b, index) => {
          const rate = rates[index] || 1;
          return +a + +(b.closing_balance * rate);
        },
        0
      ) + (parseInt(this.state.closing_stock) || 0);

    const tableRowsCredit = [
      ...this.state.creditors,
      ...this.state.liabilites_ledgers,
    ].map((arrVoucher, index) => (
      <TableRowCredit arrVoucher={arrVoucher} changeRate={this.changeRate} />
    ));
    const totalCredit =
      [...this.state.debtors, ...this.state.asset_ledgers].reduce(
        (a, b, index) => {
          const rate = 1;
          return +a + +(b.closing_balance * rate);
        },
        0
      ) +
      this.state.cash_acc +
      this.state.icici_acc +
      this.state.fish_stock +
      this.state.pack_stock +
      (totalInc > totalExp ? totalInc - totalExp : 0) +
      -1 * (parseInt(this.state.opening_stock) || 0);

    return (
      <div class="wrapper">
        <Nav />
        <div class="content-wrapper">
          <section class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-6">
                  <h1>Balance Sheet</h1>
                </div>
              </div>
            </div>
          </section>

          <div class="content">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Date</label>
                  <DatePicker
                    className={"form-control"}
                    onChange={this.onDateFromChange}
                    value={this.state.toDate}
                    format={"dd/MM/yyyy"}
                  />
                </div>
              </div>
              {this.state.loading && (
                <div class="col-md-12 mb-3">
                  <div class="text-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              )}
              <div class="col-md-6">
                <div class="card card-info">
                  <div class="card-body p-0">
                    <table class="table">
                      <thead>
                        <tr>
                          <th style={{ width: "50%" }}>Asset</th>
                          <th style={{ width: "20%" }}></th>
                          <th style={{ width: "30%" }}>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableRowsCredit}
                        {Math.round(totalInc - totalExp) < 0 && (
                          <tr>
                            <td>Loss</td>
                            <td></td>
                            <td align="right">
                              {-1 * Math.round(totalInc - totalExp)}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>CLOSING STOCK</td>
                          <td></td>
                          <td align="right">
                            <input
                              type="number"
                              onChange={(e) => this.handleChangeClosingStock(e)}
                              value={this.state.closing_stock}
                              class="form-control"
                            />
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Total</th>
                          <th></th>
                          <th style={{ textAlign: "right" }}>
                            {Math.round(totalDebt)}
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card card-info">
                  <div class="card-body p-0">
                    <table class="table">
                      <thead>
                        <tr>
                          <th style={{ width: "60%" }}>Liabilities</th>
                          <th style={{ width: "40%" }}>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableRowsDebt}
                        <tr>
                          <td>CASH ACCOUNT</td>
                          <td align="right">
                            {Math.round(this.state.cash_acc)}
                          </td>
                        </tr>
                        <tr>
                          <td>ICICI BANK</td>
                          <td align="right">
                            {Math.round(this.state.icici_acc)}
                          </td>
                        </tr>
                        {/* <tr>
                        <td>STOCK (FISH)</td>
                        <td align="right">
                          {Math.round(this.state.fish_stock)}
                        </td>
                      </tr> */}
                        {/* <tr>
                          <td>OPENING STOCK</td>
                          <td align="right">
                            <input
                              type="number"
                              onChange={(e) => this.handleChangeOpeningStock(e)}
                              value={this.state.opening_stock}
                              class="form-control"
                            />
                          </td>
                        </tr> */}
                        {/* <tr>
                          <td>STOCK (PACKING MATERIAL)</td>
                          <td align="right">
                            {Math.round(this.state.pack_stock)}
                          </td>
                        </tr> */}
                        {Math.round(totalInc - totalExp) > 0 && (
                          <tr>
                            <td>Profit</td>
                            <td align="right">
                              {Math.round(totalInc - totalExp)}
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Total</th>
                          <th style={{ textAlign: "right" }}>
                            {-1 * Math.round(totalCredit)}
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <button
              onClick={() => this.setState({ showPdf: true })}
              type="submit"
              class="btn btn-primary float-right"
            >
              Print
            </button>
          </div>
          {this.state.showPdf && (
            <div class="row">
              <div class="col-lg-12">
                <div class="card card-info">
                  <div class="card-header">
                    <h3 class="card-title">Pdf Viewer</h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        onClick={() => this.setState({ showPdf: false })}
                        class="btn btn-tool"
                        data-card-widget="remove"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <PDFViewer style={{ width: "100%", height: 500 }}>
                        <PdfBalanceSheet
                          {...this.state}
                          loss={loss}
                          totalDebt={totalDebt}
                          totalCredit={totalCredit}
                        />
                      </PDFViewer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

class TableRowDebt extends React.Component {
  handleChangeRate(account_head, e) {
    this.props.changeRate(account_head, e.target.value);
  }

  render() {
    let arrVoucher = this.props.arrVoucher;
    const rate = this.props.rate || 1;

    return (
      <tr>
        <td>{arrVoucher.account_head}</td>
        {/* <td>
          <input
            type="text"
            style={{ textAlign: "right", width: 100 }}
            onChange={(e) => this.handleChangeRate(arrVoucher.account_head, e)}
            value={this.props.rate}
          />
        </td> */}
        <td align="right">
          {-1 * Math.round(arrVoucher.closing_balance * rate)}
        </td>
      </tr>
    );
  }
}

class TableRowCredit extends React.Component {
  render() {
    let arrVoucher = this.props.arrVoucher;
    const rate = 1;

    return (
      <tr>
        <td>{arrVoucher.account_head}</td>
        <td></td>
        <td align="right">{Math.round(arrVoucher.closing_balance * rate)}</td>
      </tr>
    );
  }
}

export default BalanceSheet;
